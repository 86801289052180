import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'

import mainCover from '../images/main-cover.jpg'
import Cover from '../components/Cover'
import IntroBox from '../components/IntroBox'
import Stories from '../components/Stories'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Cover title="Informarsi Conviene" color="white" bg={mainCover} scroll>
      <p>
        <strong>
          Uno spazio dedicato da OCF all’informazione sulla consulenza
          finanziaria.
        </strong>
      </p>
      <p>
        In un panorama economico sempre più complesso per ogni risparmiatore è
        fondamentale{' '}
        <strong>
          conoscere i meccanismi di base del risparmio e degli investimenti.
        </strong>{' '}
        Vediamo qualche sintetica indicazione per scoprire perché{' '}
        <strong>informarsi conviene.</strong>
      </p>
    </Cover>
    <IntroBox />
    <Stories />
  </Layout>
)

export default IndexPage
