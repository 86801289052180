import React from 'react'
import {Link} from 'gatsby'
import Icon from './Icon'

const Story = ({image, title, excerpt, link}) => {
  return (
    <div className="story">
      <div className="story__left">
        <img src={image} alt={title} />
      </div>
      <div className="story__right">
        <h3>{title}</h3>
        <p>{excerpt}</p>
        <Link to={link} className="button button--with-icon">
          Scopri <Icon type="arrow" />
        </Link>
      </div>
    </div>
  )
}

export default Story
