import React from 'react'
import {Link} from 'gatsby'
import Icon from './Icon'
import Mouse from './Mouse'

import image from '../images/ocf-image-01.png'
import imageMobile from '../images/ocf-image-01-mobile.png'

const IntroBox = () => {
  return (
    <section className="intro-box">
      <div className="intro-box__inner">
        <div className="intro-box__left">
          <img
            className="intro-box__image-desktop"
            src={image}
            alt="L’importanza di farsi consigliare"
          />
          <img
            className="intro-box__image-mobile"
            src={imageMobile}
            alt="L’importanza di farsi consigliare"
          />
        </div>
        <div className="intro-box__right">
          <h2>
            L’importanza di
            <br />
            farsi consigliare
          </h2>
          <p>
            Il primo passo da fare, per chi affronta anche la più semplice
            attività finanziaria – come, ad esempio, un piccolissimo piano di
            accantonamento mensile per l’educazione dei propri figli – è{' '}
            <strong>sapere a chi conviene rivolgersi</strong>. In questo caso,
            la scelta più sicura è affidarsi a un consulente finanziario
            iscritto allo specifico Albo tenuto da OCF, la cui professionalità è
            garantita dall’Organismo attraverso un severo percorso di selezione.
            Che si tratti di un professionista abilitato all’offerta fuori sede
            per una banca o per una SIM (Società d’Intermediazione Mobiliare),
            di un consulente autonomo o operante per una società di consulenza
            finanziaria, l’iscrizione all’Albo assicura non solo le capacità
            tecniche, ma anche gli standard etici di integrità e onorabilità
            della persona.
            <br />
            L’OCF, l’organismo adibito alla tenuta e alla gestione dell’Albo
            Unico dei Consulenti Finanziari, è un’associazione senza finalità di
            lucro, dotata di personalità giuridica dall’11 ottobre 2007.
          </p>
          <Link
            to="/la-consulenza-finanziaria"
            className="button button--with-icon"
          >
            Scopri di più <Icon type="arrow" />
          </Link>
          {/* <a href="#temi" className="button button--with-icon">I Temi <Icon type="arrowDown" /></a> */}
          <div>
            <Mouse dark />
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntroBox
