import React from 'react'
import Story from './Story'

import thumbOne from '../images/pianificazione-finanziaria-small.png'
import thumbTwo from '../images/finanza-impulsivita-small.png'
import thumbThree from '../images/diversificazione-portafoglio-small.png'
import thumbFour from '../images/speculazione-small.png'
import thumbFive from '../images/trading-online-small.jpg'
import thumbSix from '../images/rapporto-rischi-rendimenti-small.jpg'
import thumbSeven from '../images/abusivismo-in-finanza-small.jpg'
import thumbEight from '../images/criptovalute-e-monete-virtuali-small.jpg'

const Stories = () => {
  return (
    <section id="temi" className="stories">
      <div className="stories__inner">
        <div className="stories__title">
          <span>In collaborazione con</span>
          <h3>Il Politecnico di&nbsp;Milano</h3>
        </div>
        <Story
          image={thumbOne}
          title="Pianificazione finanziaria"
          excerpt="Le attenzioni che metti nella gestione quotidiana possono
              diventare una grande sicurezza, per te e la tua famiglia, con i
              consigli giusti. Ci sono molte occasioni in cui la giusta
              consulenza può proteggere il tuo futuro. Più di quanto pensi, può
              guidarti alla scoperta di possibilità che non consideravi nemmeno."
          link="/pianificazione-finanziaria"
        />
        <Story
          image={thumbTwo}
          title="Finanza ed impulsività"
          excerpt="La complessità del panorama finanziario non consente valutazioni
              impulsive. Le scelte che hanno maggiori opportunità di risultare
              vincenti sono sempre guidate da un’analisi attenta e qualificata.
              La razionalità, sostenuta dalla competenza, è sempre il criterio
              più responsabile per decidere un investimento. Le emozioni
              conviene conservarle per i risultati ottenuti."
          link="/finanza-ed-impulsivita"
        />
        <Story
          image={thumbThree}
          title="Diversificazione del&nbsp;portafoglio"
          excerpt="I rischi sono connessi al concetto stesso di investimento, anzi ne
              costituiscono un decisivo fattore di valutazione: più rischio può
              significare più guadagno, ma anche maggiori possibilità di
              perdita. Puntare su più opzioni permette di ridurre eventuali
              contraccolpi. Con la guida giusta puoi costruirti un ventaglio più
              ampio di opportunità."
          link="/diversificazione-del-portafoglio"
        />
        <Story
          image={thumbFour}
          title="I rischi della speculazione"
          excerpt="Forse il termine “giocare in borsa” viene preso da qualcuno troppo letteralmente. La vicenda di una nota azienda di videogiochi dimostra quali complesse dinamiche si possano innescare quando un gruppo di persone, spinte da una motivazione emotiva, si lanci in un’operazione finanziaria di dimensioni colossali. Questo recente caso di cronaca è un esempio perfetto di come una certa finanza “fai-da-te” possa riservare amare sorprese a chi ci si imbarca, seguendo una tendenza invece che i consigli di un esperto."
          link="/i-rischi-della-speculazione"
        />
        <Story
          image={thumbFive}
          title="Trading online"
          excerpt="Quello che caratterizza il trading online è l’apparente facilità di operare – e guadagnare – in una moltitudine di mercati, che agli occhi degli investitori meno esperti possono apparire come molto accessibili. Nella realtà, il web può rendere più facile anche il “lavoro” di operatori meno onesti, che hanno la possibilità di abbagliare i potenziali clienti con facili promesse, senza poi mantenerle.  L’affidabilità in finanza non dovrebbe mai essere un optional."
          link="/trading-online"
        />
        <Story
          image={thumbSix}
          title="Il rapporto fra rischi e rendimenti"
          excerpt="C’è una proporzione abbastanza diretta, in finanza, fra la possibilità di guadagno e la propensione al rischio. Almeno a livello schematico, per descrivere in modo sommario la rappresentazione dei diversi livelli di investimento si usa il termine “piramide”. Più si aspira alla vetta, più il cammino diventa difficile e rischioso. Meglio saperlo. E non avventurarsi senza una guida fidata."
          link="/rapporto-rischi-rendimenti"
        />
        <Story
          image={thumbSeven}
          title="L’abusivismo in finanza"
          excerpt="Nonostante l’attività di controllo di alcune autorità competenti, la rete non è un ambiente completamente tutelato rispetto alle attività finanziarie condotte da operatori non autorizzati. L’abusivismo è diffuso e così anche il rischio di perdere i propri risparmi, se si sceglie di affrontare da soli un investimento online. Come detto più volte, il “fai-da-te” in finanza raramente paga."
          link="/abusivismo-in-finanza"
        />
        <Story
          image={thumbEight}
          title="Criptovalute e monete virtuali"
          excerpt="Potrebbe bastare la definizione ad alimentare qualche dubbio su questi valori come investimenti di sicuro rendimento. Le caratteristiche di questi asset sono tali da renderli articoli riservati a chi ami rischiare – e possa permetterselo. D’altra parte le speculazioni hanno questo limite: espongono chi le sceglie ad esiti anche catastrofici. Se le valute sono molto volatili, anche i risparmi possono volatilizzarsi."
          link="/criptovalute-e-monete-virtuali"
        />
      </div>
    </section>
  )
}

export default Stories
